import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import ResultTable from '../../containers/player/ResultTable';
import DecisionFormContainer from '../../containers/player/DecisionFormContainer';
import ReviewFormContainer from '../../containers/player/ReviewFormContainer';
import UsernameFormContainer from '../../containers/player/UsernameFormContainer';

import Timer from '../../components/Timer';
import {
  selectCurrentPeriod, selectCurrentRunuser, selectLastDecisions,
  selectSurveyPeriod, selectSurveyDecision, selectCurrentRunRoundTime, selectPreviousPeriod
} from '../../selectors/simpl';
import { submitDecision, sendMessage, submitSurvey } from '../../actions/Actions';
import { selectPlayerFormPrice } from '../../selectors/reduxform';


import styles from './player-home.scss';

class PlayerHome extends React.Component {
  constructor(props) {
    super(props);

    //TODO: add randomness here
    const playerBSubmitTime = props.roundTime + (props.avgWaitTime / 2);
    const playerCSubmitTime = props.roundTime + props.avgWaitTime;

    this.state = {
      playerBSubmitTime: playerBSubmitTime,
      playerCSubmitTime: playerCSubmitTime,
      playerBSubmitted: false,
      playerCSubmitted: false,
      time: Math.max(playerBSubmitTime, playerCSubmitTime) + 2,
      showModal: false,
      steps: [
        {
          target: 'body',
          title: <b>Your Task</b>,
          content: 'You will be assigned to be the pricing manager for a business. You will have to set your price each quarter over the next few years.',
          disableBeacon: true,
          placement: 'center',
        },
        {
          target: 'body',
          title: <b>Your Objective</b>,
          content: 'To maximize profits each and every quarter over the duration of your employment.',
          disableBeacon: true,
          placement: 'center',
        },
        {
          target: '.price-input',
          title: <b>Your Price</b>,
          content: 'Enter your estimated price for the next quarter here.',
          disableBeacon: true,
          offset: 0,
        },
        {
          target: 'body',
          title: <b>Assumptions</b>,
          content: <div>
            <div>1. Variable costs are currently $1.00/unit</div>
            <div>2. Fixed costs are $180/quarter.</div>
            <div>3. Capacity is not a constraint i.e. it is available and free.</div>
            <div>4. You have only two competitors. Both have deep pockets.</div>
            <div>5. You are at the flat part of the experience curve</div>
            <div>6. There are no major differences in other marketing mix factors, including product. Other managers are working on these items </div>
            <p><b>If any of the above change, you will be notified.</b></p>
          </div>,
          disableBeacon: true,
          placement: 'center',
        },
      ],
      runJoyride: false,
    }

    const tick = this.tick.bind(this);
    this.intervId = setInterval(tick, 1000);
    this.resetTimer = this.resetTimer.bind(this);
    this.renderModalBody = this.renderModalBody.bind(this);
    this.dismissMessage = this.dismissMessage.bind(this);
  }

  handleToggleHelp = () => {
    this.setState({
      runJoyride: true
    })
  }

  renderReview = () => {
    const { simpl, submitSurvey, surveyPeriod } = this.props;
    if (simpl.run[0]) {
      if (simpl.period.length >= simpl.run[0].data.rounds) {
        return (
          <div className={styles.reviewModal}>
            <div className={styles.reviewModalContent}>
              <ReviewFormContainer submitSurvey={submitSurvey} surveyPeriod={surveyPeriod} />
            </div>
          </div>
        );
      }
    }
    return null;
  }

  renderDecisionForm = () => {
    const { simpl, currentPeriod } = this.props;
    const maxTime = Math.max(this.state.playerBSubmitTime, this.state.playerCSubmitTime) + 2;
    const waitTime = maxTime - this.props.roundTime;
    if (simpl.period.length >= simpl.run[0].data.rounds) {
      return (
        null
      );
    }
    return (
      <React.Fragment>
        {!this.state.showModal && <Timer time={this.state.time - waitTime} />}
        <DecisionFormContainer currentPeriod={currentPeriod} openModalFunc={() => this.setState({ showModal: true })} />
      </React.Fragment>
    )
  }

  joyrideChanged = (e) => {
    if (e.action === "reset") {
      this.setState({
        runJoyride: false
      })
    }
  }

  render() {
    const { runuser, surveyDecision } = this.props;
    if (surveyDecision && !isEmpty(surveyDecision.data)) {
      return (
        <div className={styles.endMessage}>
          <h1>Congratulations you've been promoted!</h1>
          <h3> Please wait for further instructions.</h3>
          <a href="/logout/">Logout</a>
          <ResultTable />
        </div>
      )
    }
    return (
      <div className={styles.container}>

        {this.renderReview()}

        {!runuser.data.name && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <UsernameFormContainer
                runuserID={runuser.id}
              />
            </div>
          </div>
        )}
        <div className={styles.bar}>
          <div className={styles.barContent}>
            <span className={styles.headline}>
              You are Company {runuser.data.company_name && runuser.data.company_name}!
              </span>
            <a href="/logout/">Logout</a>
            {this.renderDecisionForm()}
          </div>
        </div>
        {this.renderMessage()}
        <ResultTable />
        {this.state.showModal && (
          <div className={styles.statusBar}>
            <div className={styles.statusBarContent}>
              Waiting for other players
              {this.renderModalBody()}
            </div>
          </div>
        )}
        <Joyride
          steps={this.state.steps}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          run={this.state.runJoyride}
          callback={this.joyrideChanged}
          styles={{
            options: {
              zIndex: 10000,
              arrowColor: '#fff',
              backgroundColor: '#fff',
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: '#5850ec',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: undefined,
            },
          }}
        />
        <div
          className={styles.help}
          onClick={this.handleToggleHelp}
        >
          ?
        </div>
      </div>
    );
  }

  renderMessage() {
    if (this.props.message != undefined && this.props.message != "") {
      return (
        <div className={styles.alert}>
          <div className={styles.alertContent}>
            {/* <a href="#" onClick={this.dismissMessage}>&times;</a> */}
            <div className={styles.alterHeader}>
              Message from your boss:
            </div>
            <div className={styles.alertMessage}>
              {this.props.message}
            </div>
          </div>
        </div>
      );
    }
  }

  determineOtherCompanyNames() {
    let companyList = ['AMO', 'BRP', 'CED'];
    let company = this.props.runuser.data.company_name;
    let newList = companyList.filter(x => x !== company);
    return (newList);
  }

  renderModalBody() {
    let companies = this.determineOtherCompanyNames();
    let companyB = companies[0];
    let companyC = companies[1];

    let playerBText = `Waiting for Player ${companyB}...`;
    let playerCText = `Waiting for Player ${companyC}...`;
    if (this.state.playerBSubmitted) {
      playerBText = `Player ${companyB}: Submitted!`;
    }
    if (this.state.playerCSubmitted) {
      playerCText = `Player ${companyC}: Submitted!`;
    }
    return (
      <React.Fragment>
        <span>{playerBText}</span>
        <span>{playerCText}</span>
      </React.Fragment>
    );
  }

  tick() {
    const {
      formPrice, currentPeriod, roundTime, submitDecision, lastDecision
    } = this.props;

    if (this.state.time > 0) {
      const maxTime = Math.max(this.state.playerBSubmitTime, this.state.playerCSubmitTime) + 2;
      const waitTime = maxTime - roundTime;
      if (!this.state.playerBSubmitted && (this.state.time < maxTime - this.state.playerBSubmitTime)) {
        this.setState({ playerBSubmitted: true });
      }
      if (!this.state.playerCSubmitted && (this.state.time < maxTime - this.state.playerCSubmitTime)) {
        this.setState({ playerCSubmitted: true });
      }
      if (this.state.time <= waitTime) {
        this.setState({ showModal: true })
      }
      this.setState({ time: this.state.time - 1 });
    } else {
      this.resetTimer();
      let price = formPrice;
      if (formPrice > 74 && formPrice < 399) {
        price = formPrice / 100;
      } else if (formPrice < 0.75 || formPrice > 4) {
        price = lastDecision.data.price;
      }
      submitDecision(currentPeriod, price);
    }
  }

  resetTimer() {
    const playerBSubmitTime = this.props.roundTime + (this.props.avgWaitTime / 2);
    const playerCSubmitTime = this.props.roundTime + this.props.avgWaitTime;
    this.setState({
      playerBSubmitTime: playerBSubmitTime,
      playerCSubmitTime: playerCSubmitTime,
      playerBSubmitted: false,
      playerCSubmitted: false,
      time: Math.max(playerBSubmitTime, playerCSubmitTime) + 2,
      showModal: false,
    });
  }

  dismissMessage() {
    this.props.sendMessage(this.props.runuser.id, "");
  }
}

PlayerHome.propTypes = {
  currentPeriod: PropTypes.object.isRequired,
  runuser: PropTypes.object.isRequired,
  message: PropTypes.string,
  submitDecision: PropTypes.func,
  sendMessage: PropTypes.func,
  roundTime: PropTypes.number.isRequired,
  avgWaitTime: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  let message = undefined;
  const previousPeriod = selectPreviousPeriod(state);
  if (previousPeriod.data.message) {
    message = previousPeriod.data.message
  }
  return {
    currentPeriod: selectCurrentPeriod(state),
    previousPeriod: selectPreviousPeriod(state),
    runuser: selectCurrentRunuser(state),
    surveyPeriod: selectSurveyPeriod(state),
    message: message,
    formPrice: selectPlayerFormPrice(state),
    surveyDecision: selectSurveyDecision(state),
    roundTime: selectCurrentRunRoundTime(state),
    lastDecision: selectLastDecisions(state),
    avgWaitTime: 10,
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    //dispatch SUBMIT_DECISION using the current period and form price
    submitDecision: (currentPeriod, formPrice) => dispatch(submitDecision(currentPeriod, formPrice)),
    submitSurvey: (surveyPeriod, data) => dispatch(submitSurvey(surveyPeriod, data)),
    sendMessage: (runUserId, message) => dispatch(sendMessage(runUserId, message)),
  }
}

const module = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerHome);

export default module;
