// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".timer__container___2zkXs{display:flex;flex-direction:row;justify-content:center}.timer__container___2zkXs span{white-space:nowrap;font-size:14px}\n", "",{"version":3,"sources":["timer.scss"],"names":[],"mappings":"AAAA,0BAAW,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,+BAAgB,kBAAkB,CAAC,cAAc","file":"timer.scss","sourcesContent":[".container{display:flex;flex-direction:row;justify-content:center}.container span{white-space:nowrap;font-size:14px}\n"]}]);
// Exports
exports.locals = {
	"container": "timer__container___2zkXs"
};
module.exports = exports;
