import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer,
  Legend, Tooltip
} from 'recharts';

import { getRunAverageResults, getRunDecisions, getRunSurveys, sendMessage } from '../../actions/Actions';
import { selectRunusersByRunId } from '../../selectors/simpl';
import SurveyTable from '../../components/survey-table';
import MessagingForm from '../../components/messaging-form';
import Table from '../../components/table';

import styles from './leader-home.scss';


class LeaderRun extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const { params, getRunAverageResults, getRunDecisions, getRunSurveys } = this.props;
    getRunAverageResults(params.id);
    getRunDecisions(params.id);
    getRunSurveys(params.id);
  }

  render() {
    const { runAverageResults, runDecisions, runSurveys, runUsers } = this.props;
    // We need to hide the first 4 periods from the graph
    let redactedRunAverageResults = [];

    runAverageResults.forEach(item => {
      if (item.name !== "Period 1" && item.name !== "Period 2" && item.name !== "Period 3" && item.name !== "Period 4") {
        redactedRunAverageResults.push(item);
      }
    });

    const runDecisionsColumns = [{
      Header: 'Player',
      accessor: 'participant',
      minWidth: 240,
    }, {
      Header: 'Condition',
      accessor: 'cell',
      aggregate: (values, rows) => {
        return _.max(values)
      },
      minWidth: 120,
    }, {
      Header: 'Period',
      accessor: 'period',
      Aggregated: <span></span>,
      maxWidth: 80,
    }, {
      Header: 'Current Period',
      accessor: 'current_period',
      aggregate: (values, rows) => {
        return _.max(values)
      },
      maxWidth: 140,
    }, {
      Header: 'Cumulative profit ($)',
      id: 'cumulative_profit',
      filterable: false,
      accessor: (decision) => {
        return Number(decision.a_cum_profit.toFixed(2))
      },
      aggregate: (values, rows) => {
        const row = _.maxBy(rows, 'period');
        return row.cumulative_profit
      },
      minWidth: 144,
    }, {
      Header: 'Price submitted ($)',
      id: 'price_submitted',
      filterable: false,
      accessor: (decision) => {
        return decision.a_price.toFixed(2)
      },
      aggregate: (values, rows) => {
        const row = _.maxBy(rows, 'period');
        return row.price_submitted
      },
    }, {
      Header: 'Competitor A price',
      id: 'competitor_a_price',
      filterable: false,
      accessor: (decision) => {
        return decision.b_price.toFixed(2)
      },
      Aggregated: <span></span>,
    }, {
      Header: 'Competitor B price',
      id: 'competitor_b_price',
      filterable: false,
      accessor: (decision) => {
        return decision.c_price.toFixed(2)
      },
      Aggregated: <span></span>,
    }, {
      Header: 'Company Units',
      accessor: 'a_units',
      filterable: false,
      Aggregated: <span></span>,
    }, {
      Header: 'Industry Units',
      accessor: 'total_units',
      filterable: false,
      Aggregated: <span></span>,
    }]

    return (
      <div className={styles.container}>
        <div className={classNames(styles.bar, 'bar')}>
          <div className={styles.barContent}>
            <span><a href="/">Hello Leader</a></span>
            <span>Run ID: {this.props.runId}</span>
            <a href="/logout/">Logout</a>
          </div>
          <div className={styles.subnavigation}>
            <div className={styles.subnavigationContent}>
              <ul>
                <li><a href="#price_graph">Price Graph</a></li>
                <li><a href="#decision_data">Decision Data</a></li>
                <li><a href="#survey_data">Survey Data</a></li>
                <li><a href="#message_form">Chat with Teams</a></li>
              </ul>
            </div>
          </div>
        </div>
        <section className={styles.tableContainer} id="decision_data">
          <Table
            data={runDecisions}
            columns={runDecisionsColumns}
            filterable
            pivotBy={['participant']}
            collapseOnSortingChange={false}
            defaultSorted={[{
              id: 'period',
              desc: true
            }]}
            // https://codesandbox.io/s/3x51yzollq?file=/index.js:2019-2471
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              if (typeof filter.value === "object") {
                return row[id] !== undefined
                  ? filter.value.indexOf(row[id].toLowerCase()) > -1
                  : true;
              } else {
                return row[id] !== undefined
                  ? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                  : true;
              }
            }}
          />
        </section>
        <section className={styles.chart} id="price_graph">
          <ResponsiveContainer width='100%'>
            <LineChart width={600} height={300} data={redactedRunAverageResults}>
              <Legend verticalAlign="top" height={36} />
              <Line type="linear" dataKey="elastic" stroke="#3182ce" strokeWidth="3" />
              <Line type="linear" dataKey="inelastic" stroke="#d69e2e" strokeWidth="3" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="name" tick={{ fontSize: 12 }} />
              <YAxis domain={[1.60, 1.80]} tick={{ fontSize: 12 }} />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </section>
        <section id="message_form">
          <MessagingForm
            runUsers={runUsers}
            sendMessage={sendMessage}
          />
        </section>
        <section id="survey_data">
          <SurveyTable surveys={runSurveys} />
        </section>
      </div >
    );
  }
}

LeaderRun.propTypes = {
  runId: PropTypes.number.isRequired,
};

const mapStateToProps = (state, props) => {
  const runId = parseInt(props.params.id, 10);
  return ({
    runId,
    runAverageResults: state.leaders.runAverageResults,
    runDecisions: state.leaders.runDecisions,
    runSurveys: state.leaders.runSurveys,
    runUsers: selectRunusersByRunId(state, runId),
  });
};

const mapDispatchToProps = {
  getRunAverageResults,
  getRunDecisions,
  getRunSurveys,

};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaderRun);