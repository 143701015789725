import { createAction } from 'redux-actions';

import AutobahnReact from 'simpl-react/lib/autobahn';

// submit player decision and advance to next period
export const submitDecision =
  createAction('SUBMIT_DECISION', (period, price, ...args) =>
    AutobahnReact.publish(`model:model.period.${period.id}.submit_decision`, [price])
  );

export const sendMessage =
  createAction('SEND_MESSAGE', (runuserId, message, ...args) =>
    AutobahnReact.call(`model:model.runuser.${runuserId}.send_message`, [message])
  );

// get dict with all the run's world scenarios results and decisions
export const getRunResults = createAction('GET_RUN_RESULTS', (runID, ...args) =>
  AutobahnReact.call(`model:model.run.${runID}.get_results`)
);

export const setRunUserName = createAction('SET_RUNUSER_NAME', (runUserId, name, ...args) =>
  AutobahnReact.call(`model:model.runuser.${runUserId}.set_name`, [name])
);

export const GET_RUN_AVERAGE_RESULTS = 'GET_RUN_AVERAGE_RESULTS';

export const getRunAverageResults = createAction('GET_RUN_AVERAGE_RESULTS', (runID, ...args) => {
  return (AutobahnReact.call(`model:model.run.${runID}.get_average_results`));
}
);

export const GET_RUN_DECISIONS = 'GET_RUN_DECISIONS';

export const getRunDecisions = createAction('GET_RUN_DECISIONS', (runID, ...args) => {
  return (
    AutobahnReact.call(`model:model.run.${runID}.get_run_decisions`)
  )
});

export const GET_RUN_SURVEYS = 'GET_RUN_SURVEYS';

export const getRunSurveys = createAction('GET_RUN_SURVEYS', (runID, ...args) => {
  return (
    AutobahnReact.call(`model:model.run.${runID}.get_run_surveys`)
  )
});

export const submitSurvey =
  createAction('SUBMIT_SURVEY', (period, data, ...args) =>
    AutobahnReact.publish(`model:model.period.${period.id}.submit_survey`, [data])
  );
