import { simplReducers } from 'simpl-react/lib/reducers/combined';
import { reducer as form } from 'redux-form';

import leaders from './leaders';

const reducers = simplReducers({
  form,
  leaders,
  // Add your customer reducers here, if any.
});

export default reducers;
