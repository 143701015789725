import React from 'react';

import styles from './welcome.scss';

class Welcome extends React.Component {

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>Assumptions</h1>
          <ul>
            <li><b>1.</b> Variable costs are currently $1.00/unit</li>
            <li><b>2.</b> Fixed costs are $180/quarter.</li>
            <li><b>3.</b> Price cannot be below $0.75 or above $4.00</li>
            <li><b>4.</b> Capacity is not a constraint i.e. it is available and free.</li>
            <li><b>5.</b> You have only two competitors. Both have deep pockets.</li>
            <li><b>6.</b> You are at the flat part of the experience curve</li>
            <li><b>7.</b> There are no major differences in other marketing mix factors, including product. <br />Other managers are working on these items </li>
          </ul>
          <p>If any of the above change, you will be notified.</p>
          <a href="/game" className={styles.button}>Start</a>
        </div>
      </div>
    );
  }
}

export default Welcome;
