import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { selectLastDecisions } from '../../selectors/simpl';
import PlayerDecisionForm from '../../components/PlayerDecisionForm';

function mapStateToProps(state, ownProps) {
  //This should be the last-entered price, get it from state
  const initialValues = {
    'price': 1.70
  };
  return {
    initialValues,
    lastDecision: selectLastDecisions(state),
  };
}

const DecisionFormContainer = connect(
  mapStateToProps,
  null
)(PlayerDecisionForm);

export default withRouter(DecisionFormContainer);