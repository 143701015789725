import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

import styles from './player-username-form.scss';

class UsernameForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(values) {
    this.props.setRunUserName(this.props.runuserID, values.name);
  }

  render() {
    const { error, handleSubmit, submitting, invalid } = this.props;
    return (
      <form
        id="playerUsernameForm"
        className={styles.form}
        onSubmit={handleSubmit(this.submitForm)}
      >
        <div className={styles.header}>
          <h4>Please enter your name!</h4>
        </div>
        <Field
          name="name"
          component="input"
          type="text"
        />
        <button
          type="submit"
          className={styles.button}
          disabled={invalid || submitting}
        >
          Save
        </button>
        <div>
          {error && <div><p>{error}</p></div>}
        </div>
      </form >
    );
  }
}

UsernameForm.propTypes = {
  setRunUserName: PropTypes.func.isRequired,
  // redux-form props
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.string
};

export default reduxForm({
  form: 'playerUsernameForm'
})(UsernameForm);