import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { reduce } from 'lodash';

import { selectRuns } from '../../selectors/simpl';
import Table from '../../components/table';
import { formatDate } from '../../utils/dates';
import { selectPhaseById, selectRunusers } from '../../selectors/simpl';

import styles from './leader-home.scss';

function Home(props) {
  const { runs, currentState } = props;

  const runsColumns = [{
    Header: 'Name',
    accessor: 'name',
  },{
    Header: 'Date',
    id: 'date',
    accessor: (run) => {
      const runDate = formatDate(run.created);
      return <a href={`/leader/run/${run.id}`}>{runDate}</a>
    },
  },{
    Header: 'Phase',
    id: 'phase',
    accessor: (run) => {
      const phase = selectPhaseById(currentState, run.phase);
      return phase.name
    },
  },{
    Header: 'Players',
    id: 'players',
    accessor: (run) => {
      const numPlayers = reduce(selectRunusers(currentState), function (sum, ru) {
        return ru.run == run.id && !ru.leader
          ? sum + 1
          : sum
      }, 0);
      return numPlayers
    },
  },{
    Header: 'Decision Time',
    id: 'decision_time',
    accessor: (run) => {
      return <span>{run.data.round_time_seconds} seconds</span>
    },
  },{
    Header: 'Number of Decisions',
    id: 'number_of_decision',
    accessor: (run) => {
      return run.data.rounds
    },
  }]

  return (
    <div className={styles.container}>
      <div className={classNames(styles.bar, 'bar')}>
        <div className={styles.barContent}>
          <span className={styles.headline}>Hello Leader!</span>
          <a href="/logout/">Logout</a>
        </div>
      </div>
      <Table
        data={runs}
        columns={runsColumns}
        sorted={[{
          id: 'created',
          desc: true
        }]}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  currentState: state,
  runs: selectRuns(state),
  phases: state.simpl.phase,
});

export default connect(
  mapStateToProps,
  null
)(Home);



