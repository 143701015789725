import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

export const playerDecisionFormId = 'playerDecisionForm';

import styles from './player-decision-form.scss';


const validate = values => {
  function decimalPlaces(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));
  }

  const errors = {}
  if (values.price < 0.75) {
    errors.price = 'Price must not be lower than 0.75'
  } else if (values.price > 4.0) {
    errors.price = 'Price must not be higher than 4.0'
  } else if (decimalPlaces(values.price) > 2) {
    errors.price = 'More than 2 decimal places are not allowed'
  }
  return errors
}


class PlayerDecisionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
    }
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(values) {
    const { lastDecision, openModalFunc } = this.props;
    const lastPrice = lastDecision.data.price;
    if (values.price > (lastPrice + (lastPrice * 0.5)) || values.price < (lastPrice - (lastPrice * 0.5))) {
      this.setState({showConfirmModal: true})
    } else {
      openModalFunc();
    }
  }

  closeModalAndSubmit = () => {
    const { openModalFunc } = this.props;
    this.setState({showConfirmModal: false});
    openModalFunc();
  }

  renderField = ({ input, label, type, className, step, meta: { touched, error, warning } }) => (
    <>
      <label className={styles.label}>{label}</label>
      <input {...input} placeholder={label} type={type} className={className} step={step} />
     {(error && <div className={styles.error}><span>{error}</span></div>)}
    </>
  )

  render() {
    const { error, handleSubmit, submitting, invalid } = this.props;
    const { showConfirmModal } = this.state;

    return (
      <React.Fragment>
        {showConfirmModal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              The price you entered is different by more than 50%
              <button
                className={styles.button}
                onClick={this.closeModalAndSubmit}
              >Submit Price</button>
              <button
                className={styles.button}
                onClick={() => this.setState({showConfirmModal: false})}
              >Discard</button>
            </div>
          </div>
        )}
        <form id={playerDecisionFormId} className={styles.form} onSubmit={handleSubmit(this.submitForm)}>
          <Field
            component={this.renderField}
            type="number"
            name="price"
            id="price"
            required="true"
            step="0.01"
            className="price-input"
            label="Enter your price:"
          />
          <button
            type="submit"
            className={styles.button}
            disabled={invalid || submitting}
          >Submit Price</button>

          <div>
            {error && <div><p>{error}</p></div>}
          </div>
        </form>
      </React.Fragment>
    );
  }
}

PlayerDecisionForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  openModalFunc: PropTypes.func.isRequired,

  // redux-form props
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.string
};

export default reduxForm({
  form: playerDecisionFormId,
  validate,
})(PlayerDecisionForm);