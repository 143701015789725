// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".survey-table__table___1Kt63{min-width:100%;position:relative}.survey-table__table___1Kt63 th{letter-spacing:.05em;text-align:left;padding-left:1.5rem;padding-right:1.5rem;padding-top:.75rem;padding-bottom:.75rem;line-height:1rem;font-size:.75rem;font-weight:500;border-bottom:1px solid #e5e7eb;background-color:#f9fafb;position:sticky;top:0;cursor:pointer}.survey-table__table___1Kt63 tbody{background-color:white}.survey-table__table___1Kt63 tr{background-color:white}.survey-table__table___1Kt63 tr:nth-child(even){background-color:#f9fafb}.survey-table__table___1Kt63 td{white-space:nowrap;padding-left:1.5rem;padding-right:1.5rem;padding-top:1rem;padding-bottom:1rem;line-height:1.25rem;font-size:.875rem;font-weight:500;color:#6b7280}\n", "",{"version":3,"sources":["survey-table.scss"],"names":[],"mappings":"AAAA,6BAAO,cAAc,CAAC,iBAAiB,CAAC,gCAAU,oBAAoB,CAAC,eAAe,CAAC,mBAAmB,CAAC,oBAAoB,CAAC,kBAAkB,CAAC,qBAAqB,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,eAAe,CAAC,+BAA+B,CAAC,wBAAwB,CAAC,eAAe,CAAC,KAAK,CAAC,cAAc,CAAC,mCAAa,sBAAsB,CAAC,gCAAU,sBAAsB,CAAC,gDAA0B,wBAAwB,CAAC,gCAAU,kBAAkB,CAAC,mBAAmB,CAAC,oBAAoB,CAAC,gBAAgB,CAAC,mBAAmB,CAAC,mBAAmB,CAAC,iBAAiB,CAAC,eAAe,CAAC,aAAa","file":"survey-table.scss","sourcesContent":[".table{min-width:100%;position:relative}.table th{letter-spacing:.05em;text-align:left;padding-left:1.5rem;padding-right:1.5rem;padding-top:.75rem;padding-bottom:.75rem;line-height:1rem;font-size:.75rem;font-weight:500;border-bottom:1px solid #e5e7eb;background-color:#f9fafb;position:sticky;top:0;cursor:pointer}.table tbody{background-color:white}.table tr{background-color:white}.table tr:nth-child(even){background-color:#f9fafb}.table td{white-space:nowrap;padding-left:1.5rem;padding-right:1.5rem;padding-top:1rem;padding-bottom:1rem;line-height:1.25rem;font-size:.875rem;font-weight:500;color:#6b7280}\n"]}]);
// Exports
exports.locals = {
	"table": "survey-table__table___1Kt63"
};
module.exports = exports;
