import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import filter from 'lodash/filter';

import { selectCurrentRunuser, selectScenarioByRunUser, selectPeriodsByScenario, selectResultByPeriod } from '../../../selectors/simpl';
import ResultRow from './ResultRow';

import styles from './result-table.scss';

class ResultTable extends React.Component {

  renderCompanyNames = (type = 'Price') => {
    const { runuser } = this.props;
    const company_name = runuser && runuser.data.company_name;

    if (company_name === 'AMO') {
      return (
        <React.Fragment>
          <th>BRP {type}</th>
          <th>CED {type}</th>
        </React.Fragment>
      )
    }
    if (company_name === 'BRP') {
      return (
        <React.Fragment>
          <th>AMO {type}</th>
          <th>CED {type}</th>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <th>AMO {type}</th>
        <th>BRP {type}</th>
      </React.Fragment>
    )
  }

  render() {
    const { results, runuser } = this.props;
    const company_name = runuser && runuser.data.company_name;

    return (
      <div className={styles.container}>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>{company_name} Price</th>
              {this.renderCompanyNames()}
              <th>Ind Units</th>
              <th>Units</th>
              <th>Revenue</th>
              <th>Profit</th>
              <th>Cum Profit</th>
              <th>{company_name} Share</th>
              {this.renderCompanyNames('Share')}
            </tr>
          </thead>
          <tbody>
            {results.reverse().map(result => (
              <ResultRow key={result.id} result={result} runuser={runuser} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

ResultTable.propTypes = {
  results: PropTypes.array.isRequired,
};

// ResultTable container

const mapStateToProps = state => {
  const runuser = selectCurrentRunuser(state);
  const scenario = selectScenarioByRunUser(state, runuser.id);
  const periods = selectPeriodsByScenario(state, scenario.id);
  let results = periods.map(p => selectResultByPeriod(state, p.id));
  results = filter(results, r => r != undefined); // Remove undefined result for last period

  return {
    results: results,
    runuser: runuser,
  };
};

export default connect(
  mapStateToProps,
  null
)(ResultTable);
