import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UsernameForm from '../../components/UsernameForm';
import { setRunUserName } from '../../actions/Actions';

function mapStateToProps(state, ownProps) {
  const initialValues = {
  };
  return {
    initialValues
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRunUserName: (runUserId, name) => dispatch(setRunUserName(runUserId, name)),
  }
}

const UsernameFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsernameForm);

export default withRouter(UsernameFormContainer);