import React from 'react';
import PropTypes from 'prop-types';

class ResultRow extends React.Component {

  renderCompanyPrices = () => {
    const { runuser } = this.props;
    const data = this.props.result.data;
    const company_name = runuser && runuser.data.company_name;

    if (company_name === 'AMO') {
      return (
        <React.Fragment>
          <td>$ {data.a_price.toFixed(2)}</td>
          <td>$ {data.b_price.toFixed(2)}</td>
          <td>$ {data.c_price.toFixed(2)}</td>
        </React.Fragment>
      )
    }
    if (company_name === 'BRP') {
      return (
        <React.Fragment>
          <td>$ {data.a_price.toFixed(2)}</td>
          <td>$ {data.b_price.toFixed(2)}</td>
          <td>$ {data.c_price.toFixed(2)}</td>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <td>$ {data.a_price.toFixed(2)}</td>
        <td>$ {data.b_price.toFixed(2)}</td>
        <td>$ {data.c_price.toFixed(2)}</td>
      </React.Fragment>
    )
  }
  render() {
    const data = this.props.result.data;
    return (
      <tr>
        <td>{data.yr_qtr.replace('.0', '')}</td>
        {this.renderCompanyPrices()}
        <td>{data.total_units}</td>
        <td>{data.a_units}</td>
        <td>$ {data.a_revenue.toFixed(2)}</td>
        <td>$ {data.a_profit.toFixed(2)}</td>
        <td>$ {data.a_cum_profit.toFixed(2)}</td>
        <td>{data.a_mkt_share} %</td>
        <td>{data.b_mkt_share} %</td>
        <td>{data.c_mkt_share} %</td>
      </tr>
    );
  }
}

ResultRow.propTypes = {
  result: PropTypes.object.isRequired
};

export default ResultRow;
