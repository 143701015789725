import React from 'react';
import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';
import 'react-table-v6/react-table.css';

import styles from './table.scss';

class Table extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      data, columns, filterable, pivotBy,
      collapseOnSortingChange, defaultSorted, defaultFilterMethod
    } = this.props;
    if (data.length === 0) {
      return null;
    }

    return (
      <div className={styles.container}>
        <ReactTable
          data={data}
          columns={columns}
          // showPaginationBottom={false}
          defaultPageSize={30}
          minRows={0}
          filterable={filterable}
          pivotBy={pivotBy}
          collapseOnSortingChange={collapseOnSortingChange}
          defaultSorted={defaultSorted}
          defaultFilterMethod={defaultFilterMethod}
        />
      </div>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  filterable: PropTypes.bool,
  pivotBy: PropTypes.shape({}),
  defaultSortDesc: PropTypes.bool,
  collapseOnSortingChange: PropTypes.bool,
  defaultSorted: PropTypes.array,
  defaultFilterMethod: PropTypes.func,
};

Table.defaultProps = {
  filterable: false,
  pivotBy: undefined,
  collapseOnSortingChange: true,
  defaultSorted: undefined,
  defaultFilterMethod: undefined,
};

export default Table;


