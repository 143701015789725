import React from 'react';
import PropTypes from 'prop-types';

import Row from './row';

import styles from './survey-table.scss';

class SurveyTable extends React.Component {


  render() {
    const { surveys } = this.props;

    return (
      <div className={styles.container}>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>User</th>
              <th>To what extent did total industry sales grow/shrink as prices went down/up in the simulation?</th>
              <th>How large would the impact be on your profits from each of the following changes?</th>
              <th>Competitor 1 (middle column) raises his/her price by 3 cents</th>
              <th>Competitor 2 (right column) raises his/her price by 3 cents</th>
              <th>All three firms raise their prices by 3 cents</th>
            </tr>
          </thead>
          <tbody>
            {surveys.map(survey => (
              <Row key={survey.run_user_id} survey={survey}  />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

SurveyTable.propTypes = {
  surveys: PropTypes.array.isRequired
};

export default SurveyTable;

