import {
  GET_RUN_AVERAGE_RESULTS, GET_RUN_DECISIONS, GET_RUN_SURVEYS,
} from '../../actions/Actions';


const initialState = {
  runAverageResults: [],
  runDecisions: [],
  runSurveys: [],
};

const leaders = (state = initialState, action) => {
  switch (action.type) {
    case GET_RUN_AVERAGE_RESULTS: {
      return {
        ...state,
        runAverageResults: action.payload,
      };
    }
    case GET_RUN_DECISIONS: {
      return {
        ...state,
        runDecisions: action.payload,
      };
    }
    case GET_RUN_SURVEYS: {
      return {
        ...state,
        runSurveys: action.payload,
      };
    }
    default:
      return state;
  }
};

export default leaders;
