import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

import { Field, reduxForm, change } from 'redux-form';

import Table from '../table'

import styles from './messaging-form.scss';

class MessagingForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(values) {
    const { sendMessage, resetForm, dispatch } = this.props;
    if (values.message) {
      const message = values.message;
      delete values.message;
      const userIDs = Object.keys(values)
      if (userIDs.length > 0) {
        for (let i = 0; i < userIDs.length; i += 1) {
          const userID = userIDs[i];
          sendMessage(userID, message);
          dispatch(change('messagingForm', userID, null))
        }
      }
    }
  }


  render() {
    const { error, handleSubmit, submitting, invalid, runUsers } = this.props;
    const runPlayerUsers = runUsers.filter(function( obj ) {
      return obj.leader === false;
    });

    const columns = [{
      id: 'name',
      Header: 'Player Name',
      accessor: (user) => {
        if (user.data.name) {
          return user.data.name;
        }
        if (user.email) {
          return user.email;
        }
        return user.id;
      },
    }, {
      Header: 'Team Name',
      id: 'team_name',
      accessor: (user) => {
        return `${user.first_name} ${user.last_name}`
      },
    },{
      Header: 'Send Message',
      id: 'send_message',
      sortable: false,
      filterable: false,
      accessor: (user) =>{
        return (
          <Field
            name={user.id}
            component="input"
            type="checkbox"
          />
        )
      },
    }]

    return (
      <form
        id="messagingForm"
        className={styles.form}
        onSubmit={handleSubmit(this.submitForm)}
      >

        <div className={styles.wrapper}>
          <div className={styles.tableContainer}>
            <Table
              data={runPlayerUsers}
              columns={columns}
            />
          </div>
          <div className={styles.messageContainer}>
            <div className={styles.header}>
              <h3>Your Message</h3>
            </div>
            <div className={styles.message}>
              <Field
                name="message"
                component="textarea"
                type="text"
              />
            </div>
            <div className={styles.actions}>
              <button
                type="submit"
                className={styles.button}
                disabled={invalid || submitting}
              >
                Submit
              </button>
            </div>
            <div>
              {error && <div><p>{error}</p></div>}
            </div>
          </div>
        </div>
      </form >
    );
  }
}

MessagingForm.propTypes = {
  setRunUserName: PropTypes.func.isRequired,
  // redux-form props
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.string
};

export default reduxForm({
  form: 'messagingForm'
})(MessagingForm);