import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import last from 'lodash/last';
import nth from 'lodash/nth';

const DOMAIN_NAME = 'simpl';

export const selectDomainState = state => state[DOMAIN_NAME];

/******************
 * PHASES
 ******************/

/**
 * Get all Phases of the game
 */
export const selectPhases = createSelector(
  selectDomainState,
  domainState => domainState.phase
);

/**
 * Select Phase by ID
 */
export const selectPhaseById = createCachedSelector(
  selectPhases,
  (state, phaseId) => phaseId,
  (phases, phaseId) => phases.find(phase => phase.id === phaseId)
)((state, phaseId) => phaseId);

/******************
 * RUNS
 ******************/

/**
 * Get all Runs of the game
 */
export const selectRuns = createSelector(
  selectDomainState,
  domainState => orderBy(domainState.run, ['name'], ['asc'])
);

/******************
 * WORLDS
 ******************/

/**
 * Get all Worlds of the game
 */
export const selectWorlds = createSelector(
  selectDomainState,
  domainState => orderBy(domainState.world, ['id'], ['asc'])
);

/**
 * Select world by ID
 */
export const selectWorldById = createCachedSelector(
  selectWorlds,
  (state, worldId) => worldId,
  (worlds, worldId) => worlds.find(world => world.id === worldId)
)((state, worldId) => worldId);

/******************
 * RUNUSER
 ******************/

/**
 * Get all RunUsers of the game
 */
export const selectRunusers = createSelector(
  selectDomainState,
  domainState => domainState.runuser
);

/**
 * Get all the RunUsers in a given Run
 */
export const selectRunusersByRunId = createSelector(
  selectRunusers,
  (state, runId) => runId,
  (runUsers, runId) => filter(runUsers, (ru) => ru.run == runId)
);

/******************
 * SCENARIO
 ******************/

/**
 * Get all Scenarios of the game
 */
export const selectScenarios = createSelector(
  selectDomainState,
  domainState => domainState.scenario
);

/**
 * Get the single Scenario belonging to a given RunUser
 */
export const selectScenarioByRunUser = createSelector(
  selectScenarios,
  (state, runUserId) => runUserId,
  (scenarios, runUserId,) => {
    return (
      scenarios.find(s => s.name !== 'survey')
    )
  }
);

/******************
 * PERIODS
 ******************/

/**
 * Get all Periods of the game
 */
export const selectPeriods = createSelector(
  selectDomainState,
  domainState => orderBy(domainState.period, ['order'], ['asc'])
);

/**
 * Get all the Periods belonging to a given Scenario
 */
export const selectPeriodsByScenario = createSelector(
  selectPeriods,
  (state, scenarioId) => scenarioId,
  (periods, scenarioId) => filter(periods, (p) => p.scenario == scenarioId)
);

export const selectLastPeriodByRunUser = createSelector(
  state => state,
  (state, runUserId) => runUserId,
  (state, runUserId) => selectScenarioByRunUser(state, runUserId)
)

/******************
 * RESULT
 ******************/

/**
 * Get all Results of all the periods in the game
 */
export const selectResults = createSelector(
  selectDomainState,
  domainState => domainState.result
);

export const selectResultByPeriod = createCachedSelector(
  selectResults,
  (state, periodId) => periodId,
  (results, periodId) => results.find(result => result.period === periodId)
)((state, periodId) => periodId);


/******************
 * CURRENT
 * WARNING: The 'current' selectors work for players only.
 ******************/

export const selectCurrent = createSelector(
  selectDomainState,
  domainState => domainState.current
);

export const selectCurrentRunuser = createSelector(
  selectDomainState,
  domainState => domainState.current_runuser
);

export const selectCurrentRun = createSelector(
  selectDomainState,
  selectCurrent,
  (state, current) => state.run.find(run => run.id == current.run)
)

export const selectCurrentRunRoundTime = createSelector(
  state => state,
  selectCurrentRun,
  (state, currentRun) => currentRun.data.round_time_seconds
)

export const selectCurrentScenario = createSelector(
  state => state,
  selectCurrentRunuser,
  (state, runuser) => selectScenarioByRunUser(state, runuser.id)
);

export const selectCurrentScenarioPeriods = createSelector(
  state => state,
  selectCurrentScenario,
  (state, scenario) => selectPeriodsByScenario(state, scenario.id)
);

export const selectCurrentPeriod = createSelector(
  selectCurrentScenarioPeriods,
  periods => last(periods)
);

export const selectPreviousPeriod = createSelector(
  state => state,
  selectCurrentScenarioPeriods,
  (state, periods) => {
    return (nth(periods, -2));
  }
);

export const selectSurveyScenario = createSelector(
  selectScenarios,
  scenarios => scenarios.find(scenario => scenario.name === 'survey')
);

export const selectSurveyPeriod = createSelector(
  state => state,
  selectSurveyScenario,
  (state, scenario) => {
    return (
      last(selectPeriodsByScenario(state, scenario.id))
    )
  }
);

export const selectSurveyDecision = createSelector(
  selectDomainState,
  domainState => domainState.decision.find(decision => decision.name === 'survey')
);

export const selectLastDecisions = createSelector(
  selectDomainState,
  domainState => last(orderBy(domainState.decision.filter(decision => decision.name === 'price'), ['id'], ['asc']))
);