import React from 'react';
import PropTypes from 'prop-types';

class Row extends React.Component {

  render() {
    const { survey } = this.props;

    return (
      <tr>
        <td>{survey.name}</td>
        <td>{survey.grow_shrink}</td>
        <td>{survey.lower_your_price}</td>
        <td>{survey.competitor_1_raises_price}</td>
        <td>{survey.competitor_2_raises_price}</td>
        <td>{survey.all_competitor_raises_price}</td>
      </tr>
    );
  }
}

Row.propTypes = {
  survey: PropTypes.object.isRequired
};

export default Row;
