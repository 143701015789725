import React from 'react';
import PropTypes from 'prop-types';

import styles from './timer.scss';

class Timer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.container}>
        <span>
          Time Remaining
          {' '}
          {this.props.time} seconds
        </span>
      </div>
    );
  }
}

Timer.propTypes = {
  time: PropTypes.number.isRequired,
};

export default Timer

