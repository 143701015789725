import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PlayerReviewForm from '../../components/PlayerReviewForm';

function mapStateToProps(state, ownProps) {
  const initialValues = {
    grow_shrink: "1",
    lower_your_price: "1",
    competitor_1_raises_price: "1",
    competitor_2_raises_price: "1",
    all_competitor_raises_price: "1",
  };
  return {
    initialValues
  };
}

const ReviewFormContainer = connect(
  mapStateToProps,
  null
)(PlayerReviewForm);

export default withRouter(ReviewFormContainer);