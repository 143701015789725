import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

import styles from './player-review-form.scss';

class PlayerReviewForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(values) {
    const { submitSurvey, surveyPeriod } = this.props;
    submitSurvey(surveyPeriod, values);
  }

  render() {
    const { error, handleSubmit, submitting, invalid } = this.props;
    return (
      <form id="playerReviewForm" className={styles.form} onSubmit={handleSubmit(this.submitForm)}>

        <div className={styles.header}>
          <h4>Please complete this short exit interview... Thank you!</h4>
        </div>

        <div className={styles.row}>
          <div className={styles.question}>
            To what extent did total industry sales grow/shrink as prices went down/up in the simulation?
            Select your answer below:
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.radio}>
            <label><Field name="grow_shrink" component="input" type="radio" value="1" /> 1 Not at all</label>
            <label><Field name="grow_shrink" component="input" type="radio" value="2" /> 2 A little</label>
            <label><Field name="grow_shrink" component="input" type="radio" value="3" /> 3 Somewhat</label>
            <label><Field name="grow_shrink" component="input" type="radio" value="4" /> 4 Much</label>
            <label><Field name="grow_shrink" component="input" type="radio" value="5" /> 5 Very Much</label>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.questionFull}>
            Suppose that you and both competitors are all pricing near $1.72 (as at the start of the simulation).
            Consider each of the following possible changes in the marked (assuming all else remains unchanged).
            How large would the impact be on your profits from each of the following changes?
            Please rank them 1 (Greatest Impact) to 4 (smallest impact).
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.question}>
            <label>You lower your price by 3 cents</label>
            <Field
              name="lower_your_price"
              id="lower_your_price"
              component="select"
              required="true"
            >
              <option value="1">1 - Greatest Impact</option>
              <option value="2">2 -</option>
              <option value="3">3 -</option>
              <option value="4">4 - Smallest Impact</option>
            </Field>
          </div>

          <div className={styles.question}>
            <label>Competitor 1 (middle column) raises his/her price by 3 cents</label>
            <Field
              name="competitor_1_raises_price"
              id="competitor_1_raises_price"
              component="select"
              required="true"
            >
              <option value="1">1 - Greatest Impact</option>
              <option value="2">2 -</option>
              <option value="3">3 -</option>
              <option value="4">4 - Smallest Impact</option>
            </Field>
          </div >

          <div className={styles.question}>
            <label>Competitor 2 (right column) raises his/her price by 3 cents</label>
            <Field
              name="competitor_2_raises_price"
              id="competitor_2_raises_price"
              component="select"
              required="true"
            >
              <option value="1">1 - Greatest Impact</option>
              <option value="2">2 -</option>
              <option value="3">3 -</option>
              <option value="4">4 - Smallest Impact</option>
            </Field>
          </div>

          <div className={styles.question}>
            <label>All three firms raise their prices by 3 cents</label>
            <Field
              name="all_competitor_raises_price"
              id="all_competitor_raises_price"
              component="select"
              required="true"
            >
              <option value="1">1 - Greatest Impact</option>
              <option value="2">2 -</option>
              <option value="3">3 -</option>
              <option value="4">4 - Smallest Impact</option>
            </Field>
          </div>
        </div>

        <button
          type="submit"
          className={styles.button}
          disabled={invalid || submitting}
        >Finish</button>

        <div>
          {error && <div><p>{error}</p></div>}
        </div>
      </form >
    );
  }
}

PlayerReviewForm.propTypes = {
  initialValues: PropTypes.object.isRequired,

  // redux-form props
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.string
};

export default reduxForm({
  form: 'playerReviewForm'
})(PlayerReviewForm);