import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';

import { simpl } from 'simpl-react/lib/decorators/simpl';

import Progress from '../components/Progress';

import LeaderHome from './leader/LeaderHome';
import LeaderRun from './leader/LeaderRun';
import PlayerHome from './player/PlayerHome';
import Welcome from './player/Welcome';

const Home = (LEADER) ? LeaderHome : Welcome;

class Root extends React.Component {

  render() {
    return (
      <Router history={browserHistory}>
        {/* player or leader home page route */}
        <Route exact path="/" component={Home} />

        {/* leader routes */}
        <Route
          exact
          path="/leader/run/:id"
          component={LeaderRun}
        />

        {/* player routes */}
        <Route exact path="/game" component={PlayerHome} />
      </Router>
    );
  }
}

Root.propTypes = {
  loadPhases: PropTypes.func,
};

const RootContainer = connect(
  null,
  null
)(Root);

const runs = RUNS.map((id) => `model:model.run.${id}`);
const runusers = RUNUSERS.map((id) => `model:model.runuser.${id}`);
const worlds = WORLDS.map((id) => `model:model.world.${id}`);
const topics = (LEADER) ? runs : runusers.concat(worlds);

export default simpl({
  authid: AUTHID,
  password: 'nopassword',
  url: `${MODEL_SERVICE}`,
  progressComponent: Progress,
  root_topic: ROOT_TOPIC,
  topics: () => topics,
  loadAllScenarios: false,
  loadWorldResults: true
})(RootContainer);
